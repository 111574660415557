<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex xs8>
        <view-title />
      </v-flex>
      <template v-if="multigroup_id">
        <v-flex xs7>
          <v-text-field
            single-line
            solo
            prepend-inner-icon="mdi-magnify"
            label="Search Sites"
            v-model="filter"
          />
        </v-flex>
        <v-flex xs4>
          <v-select
            :value="multigroup_id"
            :items="multigroupsList"
            item-text="namePlatform"
            item-value="id"
            label="Select App"
            return-object
            @change="setMultigroup"
            solo
            single-line
          ></v-select>
        </v-flex>
        <v-flex xs12>
          <v-data-table
            :headers="sitesHeaders"
            :items="sites"
            :loading="loading"
            :search="filter"
            :pagination.sync="pagination"
          >
            <v-progress-linear
              v-slot:progress
              color="blue"
              indeterminate
              height="7"
            ></v-progress-linear>
            <template v-slot:items="props">
              <tr
                style="cursor: pointer;"
                @click="
                  goToSite({
                    id: props.item.id,
                    name: props.item.name,
                    multigroupId: props.item.parentMultigroup.id,
                  })
                "
              >
                <td>{{ props.item.name }}</td>
                <td>{{ props.item.parentMultigroup.name }}</td>
                <td>{{ props.item.address.country }}</td>
                <td>{{ props.item.address.city }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ID from '@compassdigital/id';
import { defaultAppID } from '@/constants';

export default {
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.isSiteOperator) {
        vm.$router.replace({
          name: 'report-sites',
          params: {
            multigroup_id: 'all',
          },
        });
      }
    });
  },
  name: 'report-multigroups',
  props: ['multigroup_id'],
  data: () => ({
    filter: '',
    headers: [
      {
        text: 'App',
        align: 'left',
        value: 'name',
      },
    ],
    sitesHeaders: [
      {
        text: 'Site Name',
        align: 'left',
        value: 'name',
      },
      {
        text: 'Site App',
        align: 'left',
        value: 'parentMultigroup.name',
      },
      {
        text: 'Country',
        align: 'left',
        value: 'address.country',
      },
      {
        text: 'City',
        align: 'left',
        value: 'address.city',
      },
    ],
    loading: true,
    pagination: {
      rowsPerPage: 10,
    },
    appID: localStorage.reportDefaultAppID ? localStorage.reportDefaultAppID : defaultAppID,
  }),

  computed: {
    ...mapState('sites', ['multigroups', 'multigroupMap']),
    ...mapGetters('adminPanel', ['isSiteOperator']),

    multigroupsList() {
      return this.multigroups.map((i) => ({
        name: i.name.replace('(DH)', ''),
        id: i.id,
        namePlatform: `${i.name}`,
        platform: ID(i.id).provider,
      }));
    },

    sites() {
      if (this.multigroup_id !== 'All') {
        return (
          (this.multigroupMap[this.multigroup_id] &&
            this.multigroupMap[this.multigroup_id].groups) ||
          []
        );
      }
      return [];
    },
    selectedApp() {
      return this.$route.params.multigroup_id;
    },
  },

  methods: {
    setMultigroup({ id }) {
      if (localStorage.reportDefaultAppID !== id) {
        this.filter = '';
        this.goToReportSites(this.appID);
      }
      if (!localStorage.reportDefaultAppID || localStorage.reportDefaultAppID !== id) {
        localStorage.reportDefaultAppID = id;
        this.appID = id;
      }
    },
    goToReportSites(id) {
      this.$router.push({
        name: 'report-sites',
        params: {
          multigroup_id: id,
        },
      });
    },
  },
  watch: {
    selectedApp(v) {
      if (!v) {
        this.goToMultiGroup(this.appID);
      }
    },
  },

  mounted() {
    if (this.$route.params.multigroup_id !== this.appID) {
      this.goToReportSites(this.appID);
    }

    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Sales Reporting',
      to: { name: 'report-multigroup' },
      last: true,
    });

    this.$store.dispatch('sites/fetchAll').then(
      () => {
        this.loading = false;
      },
      () => {
        this.$toast.error('Could not load sites');
      },
    );
  },
};
</script>
